import React, { useEffect, useState } from 'react';
import Details from './details';
import Course from './course';
import Loader from '../../layouts/loader';
import apiService from '../../../services/apiService';

const LearningSpace = () => {
    const [userInfo, setUserInfo] = useState({});
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchUserDetails = async () => {
            try {
                let user = JSON.parse(localStorage.getItem("user_details"));
                if(user) {
                    setUserInfo(user);
                } else {
                    const token = apiService.getAuthToken()
                    const result = await apiService.get('get-org-user-info', token);
                    localStorage.setItem('user_details', JSON.stringify(result.data))
                    if(result && result.status == "success") {
                        setUserInfo(result.data);
                    }
                }
            } catch (error) {
                setLoader(false);
                console.error("Error fetching user details:", error);
            } finally {
                setLoader(false);
            }
        };

        fetchUserDetails();
    }, []);
    
    let orgId = localStorage.getItem("ORGID");
    console.log("org id", orgId);
    
    //console.log(Object.keys(userInfo).length)
    const shouldReloadDetails = !userInfo || Object.keys(userInfo).length == 0

    return (
        <>
            {loader ? <Loader /> :
                <div className='page__main'>
                    <div className="dashboard" style={{minHeight: '100vh'}}>
                        <div className='container-fluid'>
                            {console.log(userInfo)}
                            {orgId && orgId != '7c13b617-55a1-4ea6-810a-7bf05aaf52af' ? ((userInfo?.gender && userInfo?.gender != "undefined" && userInfo?.gender != "null" && userInfo?.gender != "NULL") && (userInfo?.age_group && userInfo?.age_group != "undefined" && userInfo?.age_group != "null" && userInfo?.age_group != "NULL")) ? null : <Details userInfo={userInfo} /> : " "}
                            <Course />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default LearningSpace;